import * as React from 'react';
import {graphql} from 'gatsby';
import './styles.scss';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {MarkdownRemark} from '../models/markdown-remark';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {BackgroundFigure} from '../components/shared/background-figure';
import {Markdown} from '../components/shared/markdown';
import {device} from '../models/devices';

interface PoliciesPageData extends BasePageData {
  pageData: MarkdownRemark<{
    seoTitle: string;
    subSections: Array<{
      listTitle: string;
      policyListItems: Array<{
        listItemName: string;
        link: string;
        content: string;
      }>
    }>
  }>
}

const Text = styled.article`
  color: #54595a;
  font-size: 16px;
  word-break: break-word;
  
  h1, h2, h3, h3, h4, h5, h6 {
    color: #4f40ab;
    margin: 0;
    font-weight: 500;
  }
`;

const TextContainer = styled.section`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-column-gap: 30px;
  padding-bottom: 138px;
  padding-top: 107px;
  
  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    padding: 0 24px;
    grid-row-gap: 40px;
    margin-bottom: 80px;
  }
`;

const Menu = styled.div`
  padding: 24px 16px;
  align-self: start;
  box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.205829);
  
  a {
    text-decoration: none;
    padding: 14px 16px;
    border-radius: 8px;
    color: #7B85A0;
    width: 100%;
    box-sizing: border-box;
    display: block;
    &.active {
      background: #157ee5;
      color: white;
    }
  }
`;

const PoliciesPage = ({data}: {data: PoliciesPageData}): JSX.Element => {

  const isSSR = typeof window === 'undefined';

  const [currentSection, setSection] = useState<undefined | {
    listItemName: string;
    link: string;
    content: string;
  }>();

  useEffect(() => {

    const handler = () => {
      const section = data.pageData.edges[0].node.frontmatter.subSections
        .map(section => section.policyListItems.find(i => i.link === window.location.hash))
        .find(i => !!i);
      setSection(section);
    };

    window.addEventListener('hashchange', handler, false);

    if (!window.location.hash) {
      window.location.hash = data.pageData.edges[0].node.frontmatter.subSections[0].policyListItems[0].link;
    } else {
      if (!currentSection) {
        handler();
      }
    }

    return () => {
      window.removeEventListener('hashchange', handler, false);
    };
  });
  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        theme={'dark'}
      />
      <main style={{paddingTop: 111, maxWidth: 1170, margin: '0 auto'}}>
        {
          data.pageData.edges[0].node.frontmatter.subSections.map((section, index) => (
            <TextContainer key={index}>
              <Menu>
                <header style={{fontSize: 18, fontWeight: 600, marginBottom: 22}}>{section.listTitle}</header>
                <ul>
                  {
                    section.policyListItems.map((item, index) => (
                      <li key={index}>
                        <a href={item.link} className={(item.link === currentSection?.link) ? 'active': ''}>{item.listItemName}</a>
                      </li>
                    ))
                  }
                </ul>
              </Menu>
              <Text>
                <Markdown>
                  {isSSR
                    ? data.pageData.edges[0].node.frontmatter.subSections[0].policyListItems[0].content
                    : currentSection?.content ?? ''
                  }
                </Markdown>
              </Text>
            </TextContainer>
          ))
        }
      </main>
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 812}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 36, height: 73}}
          position={{right: 0, top: 1190}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          left: 0,
          bottom: 250,
          width: 86,
          height: 155,
          overflow: 'hidden'
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#dee4fb'}
            size={{width: 60, height: 60}}
            position={{right: 30, bottom: 37}}
            style={{
              transform: `
                rotate(51deg)
                skewX(-30deg)
                scale(1,.866)
              `
            }}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default PoliciesPage;

export const pageQuery = graphql`
  {
    ...BaseData
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/site/content/policies/policies.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            subSections {
              listTitle
              policyListItems {
                listItemName
                link
                content
              }
            }
          }
        }
      }
    }
  }
`;
